<template>
  <v-container class="d-flex align-start fill-height">
    <v-row class="align-self-end">
      <v-col cols="12">
        <v-card width="500px" class="pl-15 pt-4" elevation="0">
          <v-card-title class="login-title pt-0">
            {{ t('Title_.page.welcome') }}!
          </v-card-title>
          <v-card-subtitle class="login-subtitle">
            {{ t('Title_.subTitle.loginKenbi') }}
          </v-card-subtitle>
          <v-card-text>
            <div class="mb-5">
              <v-hover v-slot="{ hover }">
                <v-card
                  :class="hover ? 'shadow-regular' : ''"
                  class="pa-3 rounded-lg"
                  height="auto"
                  outlined
                  @click="routeTo('AuthenticationQrCode')"
                >
                  <v-list-item>
                    <v-list-item-avatar width="52px" height="52px">
                      <img
                        src="@/assets/authentication/QR_icon.svg"
                        alt="QR_icon"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="section-title mb-3">
                        {{ t('Title_.page.loginPflegeApp') }}
                      </v-list-item-title>
                      <p class="section-detail mb-0 pr-3">
                        {{ t('Title_.subTitle.loginPflegeInstructions') }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-hover>
            </div>
            <div class="mb-5">
              <v-hover v-slot="{ hover }">
                <v-card
                  :class="hover ? 'shadow-regular' : ''"
                  class="pa-3 rounded-lg"
                  outlined
                  @click="routeTo('AuthenticationPhoneNumber')"
                >
                  <v-list-item>
                    <v-list-item-avatar width="52px" height="52px">
                      <img
                        src="@/assets/authentication/phone_numb_icon.svg"
                        alt="Phone"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="section-title mb-3">
                        {{ t('Title_.page.loginPhoneNumber') }}
                      </v-list-item-title>
                      <p class="section-detail mb-0 pr-3">
                        {{ t('Title_.subTitle.loginPhoneInstructions') }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-hover>
            </div>
            <div>
              <v-hover v-slot="{ hover }">
                <v-card
                  :class="hover ? 'shadow-regular' : ''"
                  class="pa-3 rounded-lg"
                  outlined
                  @click="loginWithUsername()"
                >
                  <v-list-item>
                    <v-list-item-avatar width="52px" height="52px">
                      <img
                        src="@/assets/authentication/username_login_icon.svg"
                        alt="username_login"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="section-title mb-3">
                        {{ t('Title_.page.loginUsername') }}
                      </v-list-item-title>
                      <p class="section-detail mb-0 pr-3">
                        {{ t('Title_.subTitle.loginUserInstructions') }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-hover>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="align-self-center">
      <v-col cols="12" class="d-flex flex-column">
        <div id="help" class="d-flex justify-end">
          {{ t('Msg_.areYouLost') }}
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ t('Action_.buttons.help') }}
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useRouter } from 'vue-router/composables';
import { useI18n } from 'vue-i18n-bridge';
import languages from '@/components/authentication/locales/languages';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const router = useRouter();
const { t } = useI18n({ locale: 'en', messages: languages });
const { trackPage } = amplitudeComposable();

// --- Methods ---
const routeTo = (route) => {
  trackPage(null, { routeName: route });
  router.push({ name: route });
};

const loginWithUsername = () => {
  window.location.href = `${process.env.VUE_APP_LOGIN_API}/account/login`;
};
</script>

<style lang="scss" scoped>
#help {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: $grey-regular;
  padding-right: 2rem;
  padding-top: 2rem;

  a {
    margin-left: 0.3rem;
    text-decoration: none;
  }
}
</style>
