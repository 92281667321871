<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row class="fill-height" no-gutters>
      <v-col cols="6" id="background-img" />
      <v-col cols="6" class="white d-flex flex-column">
        <div id="selector-container" class="align-self-end pt-2">
          <LanguageSelector />
        </div>
        <div v-if="$route.name === 'Authentication'" class="full-height">
          <AuthenticationWelcome />
        </div>
        <div v-else class="full-height">
          <router-view></router-view>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import AuthenticationWelcome from './authenticationWelcome/authentication-welcome.vue';
import LanguageSelector from '@/components/shared/layout/toolbar/languageSelector/language-selector.vue';

import { useMainStore } from '@/store/pinia/main.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useI18n } from 'vue-i18n-bridge';
import languages from './locales/languages';

const mainStore = useMainStore();
const { trackPage } = amplitudeComposable();

const { t } = useI18n({ locale: 'en', messages: languages });

const setAuthenticationHttpErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.authenticationHttpError');
};

setAuthenticationHttpErrorMessage();
trackPage();
</script>

<style lang="scss">
#selector-container {
  width: 60px;
}

#background-img {
  background: url(~@/assets/authentication/updated_background.png);
  background-size: cover;
}

.full-height {
  height: 100% !important;
}
</style>
